.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

body {
  font-family: 'Open Sans'
}


 @font-face {
  font-family: 'Open Sans';
  src: url('./static/OpenSans-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
 }
 
 @font-face {
  font-family: 'Open Sans';
  src: url('./static/OpenSans-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
 }
 
 @font-face {
  font-family: 'Open Sans';
  src: url('./static/OpenSans-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
 }
 
 @font-face {
  font-family: 'Open Sans';
  src: url('./static/OpenSans-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
 }


@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.compound-feed {
  border-top-style: solid;
  border-top-width: thin;
  padding-top: 1em;
  font-weight: 300;
  border-color: rgb(206, 190, 190);
}

.MuiDataGrid-root .MuiDataGrid-cell {
  white-space: normal !important;
  font-weight: 300;
  word-wrap: break-word !important;
}

a,
a:hover,
a:active,
a:visited,
a:focus {
  text-decoration: none;
}

.spectrum_string.MuiDataGrid-cell {
  cursor: pointer;
}

.center-img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}

.center-img-small {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 25%;
}

#block-stat {
  margin-top: 2em;
  background-color: #d5f5f5;
}

.molecules-count {
  margin: 1em;
  text-align: left;
}

nav.MuiPagination-root.MuiPagination-text {
  margin: .4;
  margin-bottom: 1em;
  padding-left: 1em;
  padding-right: 1em;
  border-radius: 5px;
  padding-bottom: 0.3em;
  padding-top: 0.3em;
  background-color: #d5f5f5;
}

.reg-line {
  margin-top: 1em;
}

.pagination-line {
  margin-top: 1em;
}

.pagination-line .MuiPagination-ul {
  justify-content: center;
}

@media (max-width: 650px) {
  .logo-block {
    display: none
  }
}

@media (max-width: 720px) {
  .page-title-holder {
    margin-bottom: 1em;
  }
}

@media (min-width: 960px) {
  .aa {
    position: absolute;
    bottom: 0;
  }

  .ab {
    position: relative;
    height: 100%;
    min-width: 210px;
  }

  .MuiGrid2-root.MuiGrid2-direction-xs-row.MuiGrid2-grid-md-4.pamd1 {
    padding-left: 2em;
  }
}

@media (max-width: 960px) {
  .aa {
    margin-bottom: 1em;
  }
  .center-xs {
    margin-right: auto;
  }
}

@media (max-width: 1200px) {
  .invisible-mobile {
    display: none;
  }
}

button.report-error-spectrum {
  font-size: smaller;
}

.align-right {
  display: flex;
  justify-content: right;
}

.align-left {
  display: flex;
  justify-content: left;
}

.filter-block-header {
  font-weight: 600;
}

.main-frame {
  padding-left: 2em;
  padding-right: 2em;
}

span.box-filter {
  background-color: #d5f5f5;
  padding-bottom: -1em;
  font-weight: 700;
  padding: 0.3em;
}

.spectrum-zip-type {
    font-weight: 600; 
    position: absolute; 
    left: 0; 
    width: 100%; 
    display: flex; 
    align-items: center; 
    justify-content: center;
}

.MuiPaper-root.MuiPaper-elevation.history-item {
  padding-left: 1em; 
  padding-right: 1em; 
  margin-bottom: 2em;
  text-align: center;
  border-style: solid;
  border-color: cornflowerblue;
  border-width: 2px;
  border-radius: 15px;
  font-size: large;
}

.video-responsive {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
}

.video-responsive iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}

a.ytp-impression-link {
  display: none;
}

button.ytp-large-play-button.ytp-button.ytp-large-play-button-red-bg {
  top: 80% !important;
 }

 button.MuiButton-root[color="success"]:focus {
  outline: none;
  box-shadow: none;
  background-color: #2e7d32;
}
